import { PageProps } from 'gatsby';
import * as React from 'react';
import { Seo, SeoObject as ISeoObject, SeoProps } from './Seo';

export interface SiteProps extends SeoProps {
  path: PageProps['path'];
}

const Site: React.FC<SiteProps> = ({ location, pageContext, seo, children }) => (
  <>
    <Seo location={location} pageContext={pageContext} seo={seo} />
    <div>{children}</div>
  </>
);

export type SeoObject = ISeoObject;

export { Site };
