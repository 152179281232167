import { graphql, PageProps, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

export interface SeoObject {
  name?: string
  basepath?: string
  title?: string
  description?: string
  keywords?: string[]
  type?: string
  image?: string
}

interface GQLSiteMetadata {
  site: { siteMetadata: Omit<SeoObject, 'title'> }
}

export interface SeoProps {
  location: PageProps['location']
  pageContext: PageProps['pageContext']
  seo?: SeoObject
}

export const Seo: React.FC<SeoProps> = ({ location: { pathname }, pageContext, seo }) => {
  const {
    site: { siteMetadata }
  } = useStaticQuery<GQLSiteMetadata>(graphql`
    {
      site {
        siteMetadata {
          name
          basepath
          description
          keywords
          type
          image
        }
      }
    }
  `)

  const { name, basepath, title, description, keywords, type, image } = {
    ...siteMetadata,
    ...seo,
    ...(pageContext as any).frontmatter
  } as SeoObject

  const url = `${basepath}${pathname}`

  return (
    <Helmet titleTemplate={`%s — ${name}`} defaultTitle={name}>
      <html lang='en' />

      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords?.join(', ')} />

      <link rel='canonical' href={url} />

      <meta property='og:site_name' content={name} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:type' content={type} />
      <meta property='og:image' content={`${basepath}${image}`} />
      <meta property='og:url' content={url} />
      <meta name='google-site-verification' content='PlJKCTw-Fc_sOCqZLj8meM1sQk76LZADCKIEOGiXHoQ' />
    </Helmet>
  )
}
